export function get_global_can_add_to (data) {
    if (data?.photo_data) {
        const result = data.photo_data.reduce((acc, item) => acc || item.can_add_to, false);
        return result;
    }
    return false;
}

export function get_global_is_album_required (data) {
    if (data?.photo_data) {
        const result = data.photo_data.reduce((acc, item) => acc || item.is_album_required, false);
        return result;
    }
    return false;
}

export function get_global_can_make_private (data) {
    if(data?.photo_data){
        const result = data.photo_data.reduce((acc, item) => acc || item.can_make_private, false);
        return result;
    }
    return false;
}

export function get_global_is_downloadable (data) {
    if(data?.photo_data){
        const result = data.photo_data.reduce((acc, item) => acc || item.is_downloadable, false);
        return result;
    }
    return false;
}

export function get_can_add_to (photoId, data) {
    if(data?.photo_data){
        for (const item of data?.photo_data) {
            if (item.data.some(photo => photo.photo_id === photoId)) {
                return item.can_add_to;
            }
        }
    }
}

export function get_is_album_required (photoId, data) {
    if(data?.photo_data){
        for (const item of data?.photo_data) {
            if (item.data.some(photo => photo.photo_id === photoId)) {
                return item.is_album_required;
            }
        }
    }
}

export function get_can_make_private (photoId, data) {
    if(data?.photo_data){
        for (const item of data?.photo_data) {
            if (item.data.some(photo => photo.photo_id === photoId)) {
                return item.can_make_private;
            }
        }
    }
}

export function get_is_downloadable (photoId, data) {
    if(data?.photo_data){
        for (const item of data?.photo_data) {
            if (item.data.some(photo => photo.photo_id === photoId)) {
                return item.is_downloadable;
            }
        }
    }
}

export function get_can_delete (photoId, data) {
    if(data?.photo_data) {
        const photo = data?.photo_data
            .flatMap(e => e.data) 
            .find(ele => ele.photo_id === photoId);
        if(photo){
            return photo.can_delete;
        }
    }
}